/*
 * Base structure
 */
@font-face {
  font-family: 'PublicoHeadline';
  src: url("../fonts/publico-headline/PublicoHeadline-BoldItalic-Web.eot");
  src: url("../fonts/publico-headline/PublicoHeadline-BoldItalic-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/publico-headline/PublicoHeadline-BoldItalic-Web.woff2") format("woff2"), url("../fonts/publico-headline/PublicoHeadline-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'PublicoHeadline';
  src: url("../fonts/publico-headline/PublicoHeadline-Bold-Web.eot");
  src: url("../fonts/publico-headline/PublicoHeadline-Bold-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/publico-headline/PublicoHeadline-Bold-Web.woff2") format("woff2"), url("../fonts/publico-headline/PublicoHeadline-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'PublicoHeadline';
  src: url("../fonts/publico-headline/PublicoHeadline-Italic-Web.eot");
  src: url("../fonts/publico-headline/PublicoHeadline-Italic-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/publico-headline/PublicoHeadline-Italic-Web.woff2") format("woff2"), url("../fonts/publico-headline/PublicoHeadline-Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'PublicoHeadline';
  src: url("../fonts/publico-headline/PublicoHeadline-Roman-Web.eot");
  src: url("../fonts/publico-headline/PublicoHeadline-Roman-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/publico-headline/PublicoHeadline-Roman-Web.woff2") format("woff2"), url("../fonts/publico-headline/PublicoHeadline-Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "TradeGothicBoldCondensed";
  src: url("../fonts/trade-gothic/257c802f-349c-4b4d-aefa-546d5de15ec6.eot?#iefix");
  src: url("../fonts/trade-gothic/257c802f-349c-4b4d-aefa-546d5de15ec6.eot?#iefix") format("eot"), url("../fonts/trade-gothic/1ba28851-f34b-4cb8-bf58-6a4b160ba249.woff2") format("woff2"), url("../fonts/trade-gothic/5fdc935e-9e30-442a-bbe9-8d887b858471.woff") format("woff"), url("../fonts/trade-gothic/616c4c87-a077-43f4-a9f4-f01267c13818.ttf") format("truetype"), url("../fonts/trade-gothic/c901ad5f-a842-4549-a1f4-583a97f7e169.svg#c901ad5f-a842-4549-a1f4-583a97f7e169") format("svg");
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
}

::-webkit-scrollbar {
  /* 1 */
}

::-webkit-scrollbar-button {
  /* 2 */
}

::-webkit-scrollbar-track {
  /* 3 */
}

::-webkit-scrollbar-track-piece {
  /* 4 */
}

::-webkit-scrollbar-thumb {
  /* 5 */
}

::-webkit-scrollbar-corner {
  /* 6 */
}

::-webkit-resizer {
  /* 7 */
}

a:focus, a:active, a:visited, a:link,
input:focus, input:active,
select:focus, select:active,
textarea:focus, textarea:active,
button:focus, button:active {
  outline: none;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
}

.no-mouse-pointer {
  cursor: text !important;
}

.no-mouse-pointer:focus {
  box-shadow: none !important;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix {
  display: inline-block;
}

* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

.color-pink {
  color: #ff3164 !important;
}

.color-light-grey {
  color: #999999 !important;
}

.color-white {
  color: #ffffff !important;
}

.color-black {
  color: #000000 !important;
}

html, body {
  height: 100%;
}

.font-family-publico {
  font-family: "PublicoHeadline", "Times New Roman", serif !important;
}

.font-family-tradegothic {
  font-family: "TradeGothicBoldCondensed", sans-serif !important;
  font-weight: 500 !important;
}

.font-size-smaller {
  font-size: 0.85em;
}

.nopadding, .no-padding {
  padding: 0 !important;
}

.no-margin-top {
  margin-bottom: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.gborder hr {
  border-color: #f5f5f5;
}

a.btn {
  text-decoration: none;
}

.btn-primary {
  background-color: #666666;
  border-color: transparent;
}

.btn-coral-color {
  background-color: #f53385;
  color: #ffffff;
}

body {
  font-size: 18px;
  font-family: "PublicoHeadline", "Times New Roman", serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -moz-font-feature-settings: "liga", "kern";
  -moz-font-feature-settings: "liga=1, kern=1";
  -ms-font-feature-settings: "liga", "kern";
  -o-font-feature-settings: "liga", "kern";
  -webkit-font-feature-settings: "liga", "kern";
  font-feature-settings: "liga", "kern";
  background-color: #f5f5f5;
}

/*
 * Sidebar
 */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #333333;
  color: #1A1A1A;
}

.sidebar .nav {
  padding: 20px 0 20px 20px;
}

.sidebar .nav-item {
  margin-bottom: 1rem;
  width: 100%;
}

.sidebar .nav-item .nav-item {
  margin-bottom: 0;
}

.sidebar .nav-item .nav-item a {
  padding: 0 10px;
}

.sidebar-header {
  font-size: 26px;
  margin: 0;
  color: #fff;
  padding: 20px;
}

.sidebar-header h2 {
  font-weight: normal;
}

.login-header .sidebar-header {
  padding: 0;
  font-weight: normal;
}

.font-gothic {
  font-family: "TradeGothicBoldCondensed", sans-serif;
}

.tm {
  font-size: 13.5px;
  vertical-align: super;
}

.sidebar .nav-item .nav {
  padding: 0;
}

.sidebar .nav-item .nav .nav-link {
  padding: 0.375rem 0 0.375rem 0.5rem;
  font-size: 1.1rem;
}

.sidebar ul.nav .nav-item ul.nav .nav-link {
  padding-bottom: 0;
  padding-top: 0;
}

.sidebar .nav-item + .nav-item {
  margin-left: 0;
}

.sidebar .nav-link {
  border-radius: 0;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  padding: 0;
  color: #999999;
  font-family: "TradeGothicBoldCondensed", sans-serif;
}

.sidebar .nav-link:hover, .sidebar .nav-link.active, .sidebar .nav-link.sub-active {
  color: #ffffff;
}

.sidebar .nav-link.active span.nav-link-underline {
  position: relative;
}

.sidebar .nav-link.active span.nav-link-underline:before {
  content: "";
  height: 3px;
  width: 100%;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  background-color: #ff3164;
}

.sidebar .nav-link.active:after {
  content: '\f0d9';
  position: absolute;
  right: -12px;
  top: 0;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 4rem;
  color: #f5f5f5;
  line-height: 1.75rem;
}

.sidebar .dropdown-divider {
  border-color: #333333;
  border-width: 1px;
  margin: 5px;
}

.policy {
  font-size: .75rem;
  margin-top: 1rem;
}

.copyright {
  font-size: .7rem;
}

.footer {
  padding-top: 3rem;
}

.account-box {
  font-size: 0.875rem;
}

.account-box a {
  text-decoration: none;
  text-transform: capitalize;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 0.875rem;
}

.account-number {
  padding-top: 0.375rem;
  padding-right: 0.5rem;
  font-family: "TradeGothicBoldCondensed", sans-serif;
}

main > header {
  margin-bottom: 0;
}

.data-correct-as {
  text-align: right;
  padding: 10px;
  margin: 0 20px 0 0;
}

.btn {
  text-transform: uppercase;
  font-size: .875rem;
  font-family: "TradeGothicBoldCondensed", sans-serif;
  padding: 0.5rem 0.875rem;
  cursor: pointer;
  border-radius: 2px;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 18px;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}

.log-in-form {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 40px;
}

.log-in-form h3 {
  padding: 20px;
  border-bottom: 1px solid #e1e1e1;
}

.log-in-form form {
  padding: 20px;
}

.log-in-form p {
  padding: 20px 20px 0 20px;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PublicoHeadline", "Times New Roman", serif;
  font-weight: 700;
}

.log-in-form button.btn {
  display: block;
  color: #fff;
  background-color: #ff3164;
  width: 100%;
  text-align: center;
}

.log-in-form button.btn:hover, .btn.btn-pink:hover {
  background-color: #000 !important;
}

.btn.btn-block {
  display: block;
  width: 100%;
  text-align: center;
}

.btn.btn-pink {
  background-color: #ff3164;
  color: #ffffff;
}

.login-header {
  padding: 20px;
  background-color: #333333;
  margin-bottom: 40px;
}

.secondary-button {
  color: #666;
  font-family: "TradeGothicBoldCondensed", sans-serif;
  text-decoration: none;
  font-size: 16px;
}

.forgotten-password-container, .return-main-website-container {
  padding: 0 20px 20px 20px;
}

.stripe-loading-indicator .stripe,
.stripe-loading-indicator .stripe-loaded {
  background: #ff3164 !important;
}

#panel-right {
  padding-left: 0;
  padding-right: 0;
}

.logged-in-header {
  background-color: #ffffff;
  width: 100%;
  max-width: none;
}

.logged-in-page-title {
  line-height: 36px;
  margin-bottom: 0;
}

.dropdown-menu {
  left: -40px !important;
}

.dropdown-item {
  font-family: "TradeGothicBoldCondensed", sans-serif;
  text-transform: uppercase !important;
  font-size: 1rem;
}

.dropdown-item:focus {
  background-color: #000000;
}

ul.list-inline li a {
  color: #999999;
  font-family: "TradeGothicBoldCondensed", sans-serif;
  font-size: 14px;
}

.copyright {
  color: #999999;
  font-family: "TradeGothicBoldCondensed", sans-serif;
  font-size: 14px;
}

.card {
  -webkit-box-shadow: 1px 1px 4px 2px rgba(136, 136, 136, 0.1);
  -moz-box-shadow: 1px 1px 4px 2px rgba(136, 136, 136, 0.1);
  box-shadow: 1px 1px 4px 2px rgba(136, 136, 136, 0.1);
  margin-bottom: 30px;
  border: 1px solid #f5f5f5;
}

.card-title {
  font-size: 1.2em;
  border-bottom: 1px solid #f5f5f5;
  padding: 0.75rem 1.25rem;
}

.header-divider {
  margin: 0 15px;
}

.theme-bar-container {
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
  margin-top: 50px;
  margin-bottom: 10px;
  border-radius: 6px;
  clear: both;
}

.theme-bar {
  height: 50px;
  width: 0;
  float: left;
  text-align: center;
  line-height: 50px;
  position: relative;
}

.theme-bar-first {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.theme-bar-last {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.theme-bar:hover {
  opacity: 0.7;
}

.theme-bar span {
  display: block;
  opacity: 0;
  width: 70px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  font-family: "TradeGothicBoldCondensed", sans-serif;
  margin: -30px auto 0 auto;
  border-radius: 6px;
  position: relative;
}

.theme-bar span:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8.7px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  bottom: -8px;
  left: 50%;
  margin-left: -5px;
}

.theme-bar-container .theme-filter-active span {
  display: block !important;
  margin-top: -50px !important;
  opacity: 1 !important;
}

.theme-bar.theme-environment-ecology, .theme-bar.theme-environment-ecology span {
  background-color: #02ca2d;
}

.theme-bar.theme-education-equality, .theme-bar.theme-education-equality span {
  background-color: #f2cd00;
}

.theme-bar.theme-enterprise-infrastructure, .theme-bar.theme-enterprise-infrastructure span {
  background-color: #2cd2fc;
}

.theme-bar.theme-governance-partnerships, .theme-bar.theme-governance-partnerships span {
  background-color: #ed4e1d;
}

.theme-bar.theme-other, .theme-bar.theme-other span {
  background-color: #666666;
}

.theme-bar.theme-environment-ecology span:before {
  border-color: #02ca2d transparent transparent transparent;
}

.theme-bar.theme-education-equality span:before {
  border-color: #f2cd00 transparent transparent transparent;
}

.theme-bar.theme-enterprise-infrastructure span:before {
  border-color: #2cd2fc transparent transparent transparent;
}

.theme-bar.theme-governance-partnerships span:before {
  border-color: #ed4e1d transparent transparent transparent;
}

.theme-bar.theme-other span:before {
  border-color: #666666 transparent transparent transparent;
}

ul.themes-legend {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
  clear: both;
}

ul.themes-legend li {
  list-style: none;
  position: relative;
  padding-left: 20px !important;
  font-family: "TradeGothicBoldCondensed", sans-serif;
  font-size: 16px;
}

ul.themes-legend li:last-child {
  padding-right: 0;
}

ul.themes-legend li.theme-filter-active {
  text-decoration: underline;
}

ul.themes-legend-horizontal {
  margin: 0;
  padding: 0;
}

ul.themes-legend-horizontal li {
  display: inline-block;
  margin: 0;
  padding: 0;
  padding-right: 20px !important;
}

ul.themes-legend.themes-legend-horizontal li:before {
  top: 4px;
}

ul.themes-legend li:before {
  position: absolute;
  top: 5px;
  left: 0;
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

ul.themes-legend li.theme-environment-ecology:before {
  background-color: #02ca2d;
}

ul.themes-legend li.theme-education-equality:before {
  background-color: #f2cd00;
}

ul.themes-legend li.theme-enterprise-infrastructure:before {
  background-color: #2cd2fc;
}

ul.themes-legend li.theme-governance-partnerships:before {
  background-color: #ed4e1d;
}

ul.themes-legend li.theme-other:before {
  background-color: #666666;
}

ul.themes-legend li.asset-cash:before {
  background-color: #0c6d6a;
}

ul.themes-legend li.asset-equity:before {
  background-color: #1ab0aa;
}

ul.themes-legend li.asset-alternatives:before {
  background-color: #138f8b;
}

ul.themes-legend li.asset-fixed-income:before {
  background-color: #23dcd5;
}

ul.themes-legend li.asset-total:before {
  background-color: #000000;
}

.security-bar-container {
  width: 100%;
  height: 20px;
  background-color: #f5f5f5;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
}

.security-bar {
  height: 20px;
  width: 0;
  float: left;
  position: relative;
  border-radius: 6px;
}

.security-bar.security-asset-cash {
  background-color: #0c6d6a;
}

.security-bar.security-asset-equity {
  background-color: #1ab0aa;
}

.security-bar.security-asset-alternatives {
  background-color: #138f8b;
}

.security-bar.security-asset-fixed-income {
  background-color: #23dcd5;
}

.security-name {
  font-family: "TradeGothicBoldCondensed", sans-serif !important;
  font-weight: 500;
}

.security-portfolio-list-item {
  display: block;
  float: left;
}

.security-portfolio-list-name {
  width: 85%;
  padding-right: 5%;
  text-align: left;
}

.security-portfolio-list-pc {
  width: 15%;
  text-align: right;
}

.asset-portfolio-list-name {
  width: 85%;
  padding-right: 5%;
  text-align: left;
}

.asset-portfolio-list-pc {
  width: 15%;
  text-align: right;
}

.security-portfolio-list-item {
  display: block;
  float: left;
}

.security-portfolio-list-name-ext {
  width: 60%;
  padding-right: 5%;
  text-align: left;
}

.security-portfolio-list-pc-ext {
  width: 20%;
  text-align: left;
}

.security-portfolio-list-monetary-ext {
  width: 20%;
  text-align: right;
}

.security-portfolio-list-name-ext-sm {
  width: 45%;
  padding-right: 5%;
  text-align: left;
}

.security-portfolio-list-pc-ext-sm {
  width: 20%;
  text-align: left;
}

.security-portfolio-list-monetary-ext-sm {
  width: 35%;
  text-align: right;
}

ul.asset-allocation-fitler {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
  clear: both;
}

ul.asset-allocation-fitler li {
  float: left;
  list-style: none;
  padding: 0;
  margin: 0 10px;
  font-size: 0.9em;
}

ul.asset-allocation-fitler li:first-child {
  margin-left: 0;
}

ul.asset-allocation-fitler li:last-child {
  margin-right: 0;
}

ul.asset-allocation-fitler li a {
  display: inline-block;
  padding: 3px 10px;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  font-family: "TradeGothicBoldCondensed", sans-serif !important;
  font-weight: 500;
  text-transform: uppercase;
}

ul.asset-allocation-fitler li a:hover {
  text-decoration: none;
}

ul.asset-allocation-fitler li a.security-asset-cash {
  background-color: #0c6d6a;
}

ul.asset-allocation-fitler li a.security-asset-equity {
  background-color: #1ab0aa;
}

ul.asset-allocation-fitler li a.security-asset-alternatives {
  background-color: #138f8b;
}

ul.asset-allocation-fitler li a.security-asset-fixed-income {
  background-color: #23dcd5;
}

ul.asset-allocation-fitler li a.security-asset-total {
  background-color: #000000;
}

ul.asset-allocation-fitler li a.asset-filter-cta {
  position: relative;
}

ul.asset-allocation-fitler li a.asset-filter-cta-active:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8.7px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  bottom: -8px;
  left: 50%;
  margin-left: -5px;
}

ul.asset-allocation-fitler li a.asset-filter-cta-active.security-asset-cash:before {
  border-color: #0c6d6a transparent transparent transparent;
}

ul.asset-allocation-fitler li a.asset-filter-cta-active.security-asset-fixed-income:before {
  border-color: #23dcd5 transparent transparent transparent;
}

ul.asset-allocation-fitler li a.asset-filter-cta-active.security-asset-equity:before {
  border-color: #1ab0aa transparent transparent transparent;
}

ul.asset-allocation-fitler li a.asset-filter-cta-active.security-asset-alternatives:before {
  border-color: #138f8b transparent transparent transparent;
}

.asset-total-container {
  padding-right: 40px;
  margin-right: 15px;
  border-right: 1px solid #f5f5f5;
}

#doughnut-tooltip {
  font-family: "TradeGothicBoldCondensed", sans-serif;
  position: absolute;
  display: none;
  width: auto;
  height: auto;
  background: none repeat scroll 0 0 #f5f5f5;
  border-radius: 4px;
  color: #f5f5f5;
  font-size: 18px;
  padding: 8px;
  text-align: center;
  opacity: 1;
}

.pie svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 60%;
}

.graph {
  visibility: hidden;
}

.graph > * {
  visibility: visible;
  transition: all 200ms ease-in-out;
}

.graph:hover > * {
  opacity: 0.5;
}

.graph__percent:hover {
  stroke: #f96b07;
  opacity: 1;
}

.circliful .outer {
  fill: transparent;
  stroke: #333;
  stroke-width: 19.8;
  stroke-dasharray: 534;
  transition: stroke-dashoffset 1s;
  -webkit-animation-play-state: running;
  /* firefox bug fix - won't rotate at 90deg angles */
  -moz-transform: rotate(-89deg) translateX(-190px);
}

/* full circle 25 empty 534 */
.circliful .inner {
  fill: transparent;
  stroke: orange;
  stroke-width: 20;
  stroke-dasharray: 534;
  transition: stroke-dashoffset 1s;
  -webkit-animation-play-state: running;
  /* firefox bug fix - won't rotate at 90deg angles */
  -moz-transform: rotate(-89deg) translateX(-190px);
  stroke-dashoffset: 0;
}

.circliful {
  overflow: visible !important;
}

.svg-container {
  width: 100%;
  margin: 0 auto;
  overflow: visible;
  position: relative;
}

svg .icon {
  font-family: FontAwesome;
}

.legend-line {
  white-space: nowrap;
}

.color-box {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  display: inline-block;
  float: left;
  padding-top: 3px;
  margin: 2px 5px 0 0;
}

.legend-line {
  position: unset !important;
}

.ovPink {
  background-color: #ff3164;
  color: #ffffff;
}

.ovPink p {
  text-align: center;
}

.gborder {
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
}

.fa-angle-up {
  color: green;
}

.fa-angle-down {
  color: red;
}

.impactTooltip {
  font-family: "TradeGothicBoldCondensed", sans-serif, sans-serif;
  position: absolute;
  display: none;
  width: auto;
  height: auto;
  background: none repeat scroll 0 0 white;
  border: 0 none;
  border-radius: 4px;
  color: #000000;
  font-size: 18px;
  padding: 8px;
  text-align: center;
}

.my-impact-graph {
  display: block;
  margin: 0 auto;
}

.tribe-table-labels {
  line-height: 60px;
}

.tribe-table-labels-mobile {
  line-height: 30px;
  padding: 10px 0;
  margin-botom: 0;
}

.financial-performance-wrap {
  padding: 10px;
  height: 80px;
}

@media (max-width: 1200px) {
  .financial-performance-wrap {
    padding: 10px 0;
  }
  .overview-percentage {
    font-size: 26px;
  }
  ul.asset-allocation-fitler li {
    margin: 0 1px;
  }
  ul.asset-allocation-fitler li a {
    padding: 3px 5px;
    font-size: 0.90em;
  }
}

.mobile-menu-button-container {
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 100;
}

.mobile-menu-button {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
  display: block;
  width: 36px;
  height: 36px;
}

.mobile-menu-button span {
  display: block;
  position: absolute;
  height: 4px;
  width: 36px;
  background: #fff;
  border-radius: 4px;
  opacity: 1;
  left: 3px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.mobile-menu-button span:nth-child(1) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-menu-button span:nth-child(2) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-menu-button span:nth-child(3) {
  top: 30px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-menu-button.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
  left: 16px;
}

.mobile-menu-button.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.mobile-menu-button.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 32px;
  left: 16px;
}

.mobile-menu-header {
  background-color: #333333;
  color: #ffffff;
}

.mobile-menu-header ul.nav {
  padding-bottom: 10px;
  display: none;
}

.mobile-menu-header li {
  font-family: "TradeGothicBoldCondensed", sans-serif;
  text-transform: uppercase;
}

.mobile-menu-header a {
  text-decoration: none;
  color: #999999;
  padding: 0.3rem 1rem;
}

.mobile-menu-header a:hover, .mobile-menu-header a:focus {
  color: #ffffff;
}

.sidebar {
  display: none;
}

.logged-in-header {
  padding: 5px 0;
}

.mobile-menu-header .sidebar-header {
  padding: 15px;
}

.mobile-menu-header .sidebar-header h2 {
  margin-bottom: 0;
}

.security-doughnut-chart {
  max-width: 260px;
  margin: 0 auto;
}

.security-info-container .info {
  display: none;
}

ol ol li {
  list-style: lower-alpha;
}

@media (min-width: 768px) {
  .sidebar {
    display: block;
  }
  .logged-in-header {
    padding: 12px;
  }
  .mobile-menu-header {
    display: none;
  }
}

@media (max-width: 768px) {
  .tribe-hide-md-below {
    display: none;
  }
}

@media (min-width: 769px) {
  .tribe-hide-md-above {
    display: none;
  }
}

.overview-percentage {
  font-size: 24px;
}

@media (min-width: 1200px) {
  .overview-same-height-graphs {
    min-height: 420px;
  }
  .security-doughnut-chart {
    margin: 55px auto 0 auto;
  }
}

@media (min-width: 1400px) {
  .overview-percentage {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .my-impact-performance-filters-height {
    height: 120px;
    margin-bottom: 20px;
  }
  body {
    overflow-y: scroll;
  }
}
